.page {
  background-color: #ffffff;
  position: relative;
  width: 100vw;
  height: 147.19vw;
  overflow: hidden;
}
.section_1 {
  height: 4.59vw;
  background: url(./img/SketchPng1dce9298fb38b7e9cfe82a32c00329defb2206a439396e82849769b6fb3d9a85.png) 0vw 0vw no-repeat;
  background-size: 99.94vw 4.63vw;
  width: 99.95vw;
}
.section_8 {
  width: 3.75vw;
  height: 0.32vw;
}
.box_1 {
  width: 3.75vw;
  height: 0.32vw;
  background: url(./img/SketchPnge241ff7a4576b00b663c72f206afeda67344f75965e098c8f410d944d423b92c.png) 100% no-repeat;
  background-size: 100% 100%;
}
.section_9 {
  width: 91.72vw;
  height: 1.57vw;
  margin: 1.19vw 0 1.51vw 4.16vw;
}
.image_1 {
  width: 13.34vw;
  height: 1.57vw;
}
.image_2 {
  width: 8.39vw;
  height: 1.25vw;
  margin: 0.15vw 0 0 1.3vw;
}
.image_3 {
  width: 5.84vw;
  height: 1.57vw;
  margin-left: 1.25vw;
}
.text_1 {
  width: 1.88vw;
  height: 1.31vw;
  overflow-wrap: break-word;
  color: #334155;
  font-size: 0.93vw;
  font-family: PingFang-SC-Regular;
  font-weight: NaN;
  text-align: center;
  white-space: nowrap;
  line-height: 1.31vw;
  margin: 0.15vw 0 0 27.65vw;
}
.text_2 {
  width: 3.75vw;
  height: 1.31vw;
  overflow-wrap: break-word;
  color: #4d6bfe;
  font-size: 0.93vw;
  font-family: PingFang-SC-Medium;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 1.31vw;
  margin: 0.15vw 0 0 1.77vw;
}
.text_3 {
  width: 3.75vw;
  height: 1.31vw;
  overflow-wrap: break-word;
  color: #334155;
  font-size: 0.93vw;
  font-family: PingFang-SC-Regular;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 1.31vw;
  margin: 0.15vw 0 0 1.77vw;
}
.text_4 {
  width: 3.75vw;
  height: 1.31vw;
  overflow-wrap: break-word;
  color: #334155;
  font-size: 0.93vw;
  font-family: PingFang-SC-Regular;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 1.31vw;
  margin: 0.15vw 0 0 1.77vw;
}
.text_5 {
  width: 3.75vw;
  height: 1.31vw;
  overflow-wrap: break-word;
  color: #334155;
  font-size: 0.93vw;
  font-family: PingFang-SC-Regular;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 1.31vw;
  margin: 0.15vw 0 0 1.77vw;
}
.group_1 {
  width: 1.05vw;
  height: 1.05vw;
  background: url(./img/SketchPngcf70cb1cd4a6e4baec70c06d360ead579c68b1ac93462218499a786418461b34.png) 100% no-repeat;
  background-size: 100% 100%;
  margin: 0.31vw 0 0 1.77vw;
}
.text_6 {
  width: 6.78vw;
  height: 1.31vw;
  overflow-wrap: break-word;
  color: #334155;
  font-size: 0.93vw;
  font-family: PingFang-SC-Bold;
  font-weight: NaN;
  text-align: center;
  white-space: nowrap;
  line-height: 1.31vw;
  margin: 0.15vw 0 0 0.41vw;
}
.group_8 {
  width: 100vw;
  height: 127.56vw;
  margin-bottom: 15.06vw;
}
.group_9 {
  width: 91.67vw;
  height: 121.88vw;
  margin: 2.08vw 0 0 4.16vw;
}
.section_10 {
  width: 21.25vw;
  height: 31.25vw;
}
.text-wrapper_1 {
  border-radius: 40px;
  height: 4.17vw;
  border: 1px solid;
  width: 21.25vw;
}
.text_7 {
  width: 6.25vw;
  height: 1.57vw;
  overflow-wrap: break-word;
  color: #4d6bfe;
  font-size: 1.56vw;
  font-family: PingFang-SC-Bold;
  font-weight: NaN;
  text-align: right;
  white-space: nowrap;
  line-height: 1.57vw;
  margin: 1.3vw 0 0 7.5vw;
}
.text-wrapper_2 {
  border-radius: 40px;
  height: 4.17vw;
  border: 1px solid #93a3b8;
  margin-top: 1.25vw;
  width: 21.25vw;
}
.text_8 {
  width: 3.13vw;
  height: 2.19vw;
  overflow-wrap: break-word;
  color: #334155;
  font-size: 1.56vw;
  font-family: PingFang-SC-Regular;
  font-weight: NaN;
  text-align: right;
  white-space: nowrap;
  line-height: 2.19vw;
  margin: 0.98vw 0 0 9.06vw;
}
.text-wrapper_3 {
  border-radius: 40px;
  height: 4.17vw;
  border: 1px solid #93a3b8;
  margin-top: 1.25vw;
  width: 21.25vw;
}
.text_9 {
  width: 3.13vw;
  height: 2.19vw;
  overflow-wrap: break-word;
  color: #334155;
  font-size: 1.56vw;
  font-family: PingFang-SC-Regular;
  font-weight: NaN;
  text-align: right;
  white-space: nowrap;
  line-height: 2.19vw;
  margin: 0.98vw 0 0 9.06vw;
}
.text-wrapper_4 {
  border-radius: 40px;
  height: 4.17vw;
  border: 1px solid #93a3b8;
  margin-top: 1.25vw;
  width: 21.25vw;
}
.text_10 {
  width: 6.25vw;
  height: 2.19vw;
  overflow-wrap: break-word;
  color: #334155;
  font-size: 1.56vw;
  font-family: PingFang-SC-Regular;
  font-weight: NaN;
  text-align: right;
  white-space: nowrap;
  line-height: 2.19vw;
  margin: 0.98vw 0 0 7.5vw;
}
.text-wrapper_5 {
  border-radius: 40px;
  height: 4.17vw;
  border: 1px solid #93a3b8;
  margin-top: 1.25vw;
  width: 21.25vw;
}
.text_11 {
  width: 3.13vw;
  height: 2.19vw;
  overflow-wrap: break-word;
  color: #334155;
  font-size: 1.56vw;
  font-family: PingFang-SC-Regular;
  font-weight: NaN;
  text-align: right;
  white-space: nowrap;
  line-height: 2.19vw;
  margin: 0.98vw 0 0 9.06vw;
}
.text-wrapper_6 {
  border-radius: 40px;
  height: 4.17vw;
  border: 1px solid #93a3b8;
  margin-top: 1.25vw;
  width: 21.25vw;
}
.text_12 {
  width: 3.13vw;
  height: 2.19vw;
  overflow-wrap: break-word;
  color: #334155;
  font-size: 1.56vw;
  font-family: PingFang-SC-Regular;
  font-weight: NaN;
  text-align: right;
  white-space: nowrap;
  line-height: 2.19vw;
  margin: 0.98vw 0 0 9.06vw;
}
.box_4 {
  border-radius: 40px;
  width: 68.34vw;
  height: 121.88vw;
  border: 1px solid #93a3b8;
}
.section_11 {
  background-image: url(./img/c27ccbd5270d46369b19337e52c099d9_mergeImage.png);
  width: 68.34vw;
  height: 22.19vw;
  border: 1px solid #979797;
}
.text_13 {
  width: 39.28vw;
  height: 3.55vw;
  overflow-wrap: break-word;
  color: #334155;
  font-size: 3.02vw;
  font-family: PingFang-SC-Bold;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 3.55vw;
  margin: 2.44vw 0 0 2.29vw;
}
.text-group_21 {
  width: 53.13vw;
  height: 10.63vw;
  margin: 2.08vw 0 0 2.29vw;
}
.paragraph_1 {
  width: 53.13vw;
  height: 4.8vw;
  overflow-wrap: break-word;
  color: #65748b;
  font-size: 1.04vw;
  font-family: PingFang-SC-Regular;
  font-weight: NaN;
  text-align: justify;
  line-height: 2.4vw;
}
.text_14 {
  width: 24.02vw;
  height: 3.55vw;
  overflow-wrap: break-word;
  color: #334155;
  font-size: 2.5vw;
  font-family: PingFang-SC-Bold;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 3.55vw;
  margin-top: 2.3vw;
}
.list_3 {
  width: 63.75vw;
  height: 27.87vw;
  justify-content: space-between;
  margin: 2.08vw 0 0 2.29vw;
}
.list-items_1-0 {
  box-shadow: 0px 0px 20px 0px rgba(51, 65, 85, 0.1);
  border-radius: 40px;
  background-image: url(./img/5b025c6e6e74469aa2967a4b85e52cf9_mergeImage.png);
  width: 19.8vw;
  height: 27.87vw;
  border: 1px solid #93a3b8;
  margin-right: 2.19vw;
  background: url(./img/5b025c6e6e74469aa2967a4b85e52cf9_mergeImage.png);
}
.text_15-0 {
  width: 9.38vw;
  height: 1.57vw;
  overflow-wrap: break-word;
  color: #334155;
  font-size: 1.56vw;
  font-family: PingFang-SC-Bold;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 1.57vw;
  margin: 14.16vw 0 0 1.25vw;
}
.text_16-0 {
  width: 17.3vw;
  height: 3.34vw;
  overflow-wrap: break-word;
  color: #65748b;
  font-size: 1.04vw;
  font-family: PingFang-SC-Regular;
  font-weight: NaN;
  text-align: justify;
  line-height: 1.67vw;
  margin: 1.09vw 0 0 1.25vw;
}
.group_2-0 {
  width: 11.93vw;
  height: 3.34vw;
  background-size: 100% 100%;
  margin: 3.12vw 0 1.25vw 1.25vw;
  background: url(./img/SketchPngf6dc4cb48fc2142afa30d08efd337eeca22c77776f036c7bb1ff9d81f3c9a680.png) 100% no-repeat;
}
.image-text_28-0 {
  width: 6.88vw;
  height: 1.57vw;
  margin: 0.88vw 0 0 2.55vw;
}
.text-group_3-0 {
  width: 5vw;
  height: 1.25vw;
  overflow-wrap: break-word;
  color: #ffffff;
  font-size: 1.25vw;
  font-family: PingFang-SC-Medium;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 1.25vw;
  margin-top: 0.16vw;
}
.label_2-0 {
  width: 1.57vw;
  height: 1.57vw;
}
.list-items_1-1 {
  box-shadow: 0px 0px 20px 0px rgba(51, 65, 85, 0.1);
  border-radius: 40px;
  background-image: url(./img/5b025c6e6e74469aa2967a4b85e52cf9_mergeImage.png);
  width: 19.8vw;
  height: 27.87vw;
  border: 1px solid #93a3b8;
  margin-right: 2.19vw;
  background: url(./img/6a19cf0a4cc4455393c738c1d198be23_mergeImage.png);
}
.text_15-1 {
  width: 9.38vw;
  height: 1.57vw;
  overflow-wrap: break-word;
  color: #334155;
  font-size: 1.56vw;
  font-family: PingFang-SC-Bold;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 1.57vw;
  margin: 14.16vw 0 0 1.25vw;
}
.text_16-1 {
  width: 17.3vw;
  height: 3.34vw;
  overflow-wrap: break-word;
  color: #65748b;
  font-size: 1.04vw;
  font-family: PingFang-SC-Regular;
  font-weight: NaN;
  text-align: justify;
  line-height: 1.67vw;
  margin: 1.09vw 0 0 1.25vw;
}
.group_2-1 {
  width: 11.93vw;
  height: 3.34vw;
  background-size: 100% 100%;
  margin: 3.12vw 0 1.25vw 1.25vw;
  background: url(./img/SketchPngf6dc4cb48fc2142afa30d08efd337eeca22c77776f036c7bb1ff9d81f3c9a680.png) 100% no-repeat;
}
.image-text_28-1 {
  width: 6.88vw;
  height: 1.57vw;
  margin: 0.88vw 0 0 2.55vw;
}
.text-group_3-1 {
  width: 5vw;
  height: 1.25vw;
  overflow-wrap: break-word;
  color: #ffffff;
  font-size: 1.25vw;
  font-family: PingFang-SC-Medium;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 1.25vw;
  margin-top: 0.16vw;
}
.label_2-1 {
  width: 1.57vw;
  height: 1.57vw;
}
.list-items_1-2 {
  box-shadow: 0px 0px 20px 0px rgba(51, 65, 85, 0.1);
  border-radius: 40px;
  background-image: url(./img/5b025c6e6e74469aa2967a4b85e52cf9_mergeImage.png);
  width: 19.8vw;
  height: 27.87vw;
  border: 1px solid #93a3b8;
  margin-right: 2.19vw;
  background: url(./img/5b7a68cce3a145b49ad908b4018ef17e_mergeImage.png);
}
.text_15-2 {
  width: 9.38vw;
  height: 1.57vw;
  overflow-wrap: break-word;
  color: #334155;
  font-size: 1.56vw;
  font-family: PingFang-SC-Bold;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 1.57vw;
  margin: 14.16vw 0 0 1.25vw;
}
.text_16-2 {
  width: 17.3vw;
  height: 3.34vw;
  overflow-wrap: break-word;
  color: #65748b;
  font-size: 1.04vw;
  font-family: PingFang-SC-Regular;
  font-weight: NaN;
  text-align: justify;
  line-height: 1.67vw;
  margin: 1.09vw 0 0 1.25vw;
}
.group_2-2 {
  width: 11.93vw;
  height: 3.34vw;
  background-size: 100% 100%;
  margin: 3.12vw 0 1.25vw 1.25vw;
  background: url(./img/SketchPngcb67fa357abdd4e76d594489cea0effaef7bc90edafd4ca6f5e010f31dd89e39.png) 100% no-repeat;
}
.image-text_27-2 {
  width: 9.38vw;
  height: 1.57vw;
  margin: 0.88vw 0 0 2.55vw;
}
.text-group_2-2 {
  width: 7.5vw;
  height: 1.25vw;
  overflow-wrap: break-word;
  color: #ffffff;
  font-size: 1.25vw;
  font-family: PingFang-SC-Medium;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 1.25vw;
  margin-top: 0.16vw;
}
.label_1-2 {
  width: 1.57vw;
  height: 1.57vw;
}
.text_17 {
  width: 24.02vw;
  height: 3.55vw;
  overflow-wrap: break-word;
  color: #334155;
  font-size: 2.5vw;
  font-family: PingFang-SC-Bold;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 3.55vw;
  margin: 2.5vw 0 0 2.29vw;
}
.section_3 {
  background-color: #ffffff;
  border-radius: 40px;
  height: 16.78vw;
  border: 1px solid #93a3b8;
  width: 63.75vw;
  justify-content: flex-center;
  margin: 2.08vw 0 0 2.29vw;
}
.text-wrapper_9 {
  width: 15.63vw;
  height: 1.57vw;
  margin: 2.08vw 0 0 1.25vw;
}
.text_18 {
  width: 15.63vw;
  height: 1.57vw;
  overflow-wrap: break-word;
  color: #334155;
  font-size: 1.56vw;
  font-family: PingFang-SC-Bold;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 1.57vw;
}
.box_19 {
  width: 40.32vw;
  height: 6.88vw;
  margin: 1.56vw 0 0 2.08vw;
}
.section_12 {
  width: 12.61vw;
  height: 6.88vw;
}
.image-text_29 {
  width: 8.44vw;
  height: 1.67vw;
}
.box_6 {
  background-color: #65748b;
  border-radius: 50%;
  width: 0.42vw;
  height: 0.42vw;
  margin-top: 0.63vw;
}
.text-group_4 {
  width: 7.3vw;
  height: 1.67vw;
  overflow-wrap: break-word;
  color: #65748b;
  font-size: 1.04vw;
  font-family: PingFang-SC-Regular;
  font-weight: NaN;
  text-align: justify;
  white-space: nowrap;
  line-height: 1.67vw;
}
.image-text_30 {
  width: 12.61vw;
  height: 1.67vw;
  margin-top: 0.94vw;
}
.block_4 {
  background-color: #65748b;
  border-radius: 50%;
  width: 0.42vw;
  height: 0.42vw;
  margin-top: 0.63vw;
}
.text-group_5 {
  width: 11.46vw;
  height: 1.67vw;
  overflow-wrap: break-word;
  color: #65748b;
  font-size: 1.04vw;
  font-family: PingFang-SC-Regular;
  font-weight: NaN;
  text-align: justify;
  white-space: nowrap;
  line-height: 1.67vw;
}
.image-text_31 {
  width: 11.57vw;
  height: 1.67vw;
  margin-top: 0.94vw;
}
.block_5 {
  background-color: #65748b;
  border-radius: 50%;
  width: 0.42vw;
  height: 0.42vw;
  margin-top: 0.63vw;
}
.text-group_6 {
  width: 10.42vw;
  height: 1.67vw;
  overflow-wrap: break-word;
  color: #65748b;
  font-size: 1.04vw;
  font-family: PingFang-SC-Regular;
  font-weight: NaN;
  text-align: justify;
  white-space: nowrap;
  line-height: 1.67vw;
}
.section_13 {
  position: relative;
  width: 9.48vw;
  height: 6.88vw;
}
.image-text_32 {
  width: 9.48vw;
  height: 6.88vw;
}
.block_7 {
  background-color: #65748b;
  border-radius: 50%;
  width: 0.42vw;
  height: 0.42vw;
  margin-top: 5.84vw;
}
.text-group_22 {
  width: 8.34vw;
  height: 6.88vw;
}
.text_19 {
  width: 8.34vw;
  height: 1.67vw;
  overflow-wrap: break-word;
  color: #65748b;
  font-size: 1.04vw;
  font-family: PingFang-SC-Regular;
  font-weight: NaN;
  text-align: justify;
  white-space: nowrap;
  line-height: 1.67vw;
}
.text_20 {
  width: 6.25vw;
  height: 1.67vw;
  overflow-wrap: break-word;
  color: #65748b;
  font-size: 1.04vw;
  font-family: PingFang-SC-Regular;
  font-weight: NaN;
  text-align: justify;
  white-space: nowrap;
  line-height: 1.67vw;
  margin-top: 0.94vw;
}
.text_21 {
  width: 4.17vw;
  height: 1.67vw;
  overflow-wrap: break-word;
  color: #65748b;
  font-size: 1.04vw;
  font-family: PingFang-SC-Regular;
  font-weight: NaN;
  text-align: justify;
  white-space: nowrap;
  line-height: 1.67vw;
  margin-top: 0.94vw;
}
.image-text_33 {
  position: absolute;
  left: 0;
  top: 0;
  width: 9.48vw;
  height: 6.88vw;
}
.box_7 {
  background-color: #65748b;
  border-radius: 50%;
  width: 0.42vw;
  height: 0.42vw;
  margin-top: 0.63vw;
}
.text-group_23 {
  width: 8.34vw;
  height: 6.88vw;
}
.image-text_34 {
  position: absolute;
  left: 0;
  top: 0;
  width: 9.48vw;
  height: 6.88vw;
}
.box_8 {
  background-color: #65748b;
  border-radius: 50%;
  width: 0.42vw;
  height: 0.42vw;
  margin-top: 3.23vw;
}
.text-group_24 {
  width: 8.34vw;
  height: 6.88vw;
}
.box_20 {
  width: 8.44vw;
  height: 1.67vw;
  margin: 0.93vw 0 2.08vw 2.08vw;
}
.image-text_35 {
  width: 8.44vw;
  height: 1.67vw;
}
.group_3 {
  background-color: #65748b;
  border-radius: 50%;
  width: 0.42vw;
  height: 0.42vw;
  margin-top: 0.63vw;
}
.text-group_10 {
  width: 7.3vw;
  height: 1.67vw;
  overflow-wrap: break-word;
  color: #65748b;
  font-size: 1.04vw;
  font-family: PingFang-SC-Regular;
  font-weight: NaN;
  text-align: justify;
  white-space: nowrap;
  line-height: 1.67vw;
}
.text_22 {
  width: 19.02vw;
  height: 3.55vw;
  overflow-wrap: break-word;
  color: #334155;
  font-size: 2.5vw;
  font-family: PingFang-SC-Bold;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 3.55vw;
  margin: 2.5vw 0 0 2.29vw;
}
.section_4 {
  border-radius: 40px;
  background-image: url(./img/c5f795cd226140b99beb2937d34a407d_mergeImage.png);
  width: 63.75vw;
  height: 15.73vw;
  border: 1px solid #93a3b8;
  margin: 2.08vw 0 2.29vw 2.29vw;
}
.text_23 {
  width: 6.25vw;
  height: 1.57vw;
  overflow-wrap: break-word;
  color: #334155;
  font-size: 1.56vw;
  font-family: PingFang-SC-Bold;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 1.57vw;
  margin: 2.08vw 0 0 25.67vw;
}
.text_24 {
  width: 35.99vw;
  height: 3.34vw;
  overflow-wrap: break-word;
  color: #65748b;
  font-size: 1.04vw;
  font-family: PingFang-SC-Regular;
  font-weight: NaN;
  text-align: justify;
  line-height: 1.67vw;
  margin: 1.56vw 0 0 25.67vw;
}
.box_10 {
  width: 14.38vw;
  height: 3.34vw;
  background: url(./img/SketchPnge70cf8bbc1a2d846b2dcc75348fedd4d73f0e90199e4d7cfabae624786585a6d.png) 100% no-repeat;
  background-size: 100% 100%;
  margin: 1.56vw 0 2.29vw 25.67vw;
}
.image-text_36 {
  width: 9.38vw;
  height: 1.57vw;
  margin: 0.88vw 0 0 2.5vw;
}
.text-group_11 {
  width: 7.5vw;
  height: 1.25vw;
  overflow-wrap: break-word;
  color: #ffffff;
  font-size: 1.25vw;
  font-family: PingFang-SC-Medium;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 1.25vw;
  margin-top: 0.16vw;
}
.label_3 {
  width: 1.57vw;
  height: 1.57vw;
}
.section_5 {
  background-color: #334155;
  position: absolute;
  left: 0;
  top: 132.09vw;
  width: 99.95vw;
  height: 15.11vw;
}
.group_10 {
  width: 73.29vw;
  height: 9.28vw;
  margin: 1.61vw 0 0 4.16vw;
}
.image_4 {
  width: 3.34vw;
  height: 3.34vw;
  margin-top: 3.7vw;
}
.box_21 {
  width: 28.44vw;
  height: 7.82vw;
  margin-left: 7.24vw;
}
.text-group_25 {
  width: 28.44vw;
  height: 3.65vw;
}
.text_25 {
  width: 5vw;
  height: 1.57vw;
  overflow-wrap: break-word;
  color: #ffffff;
  font-size: 1.25vw;
  font-family: PingFang-SC-Bold;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 1.57vw;
}
.text_26 {
  width: 28.39vw;
  height: 1.15vw;
  overflow-wrap: break-word;
  color: #ffffff;
  font-size: 0.83vw;
  font-family: PingFang-SC-Regular;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 1.15vw;
  margin: 0.93vw 0 0 0.05vw;
}
.text_27 {
  width: 26.57vw;
  height: 1.15vw;
  overflow-wrap: break-word;
  color: #ffffff;
  font-size: 0.83vw;
  font-family: PingFang-SC-Regular;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 1.15vw;
  margin: 0.93vw 0 0 0.05vw;
}
.text_28 {
  width: 13.18vw;
  height: 1.15vw;
  overflow-wrap: break-word;
  color: #ffffff;
  font-size: 0.83vw;
  font-family: PingFang-SC-Regular;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 1.15vw;
  margin: 0.93vw 0 0 0.05vw;
}
.box_22 {
  width: 5.21vw;
  height: 9.28vw;
  margin-left: 13.86vw;
}
.text_29 {
  width: 5vw;
  height: 1.57vw;
  overflow-wrap: break-word;
  color: #ffffff;
  font-size: 1.25vw;
  font-family: PingFang-SC-Bold;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 1.57vw;
}
.image-text_37 {
  width: 5.21vw;
  height: 6.78vw;
  margin-top: 0.94vw;
}
.section_6 {
  background-color: #ffffff;
  width: 5.21vw;
  height: 5.21vw;
}
.text-group_13 {
  width: 2.5vw;
  height: 1.15vw;
  overflow-wrap: break-word;
  color: #ffffff;
  font-size: 0.83vw;
  font-family: PingFang-SC-Regular;
  font-weight: NaN;
  text-align: right;
  white-space: nowrap;
  line-height: 1.15vw;
  margin: 0.41vw 0 0 1.35vw;
}
.image-text_38 {
  width: 5.21vw;
  height: 6.78vw;
  margin: 2.5vw 0 0 2.39vw;
}
.section_7 {
  background-color: #ffffff;
  width: 5.21vw;
  height: 5.21vw;
}
.text-group_14 {
  width: 2.5vw;
  height: 1.15vw;
  overflow-wrap: break-word;
  color: #ffffff;
  font-size: 0.83vw;
  font-family: PingFang-SC-Regular;
  font-weight: NaN;
  text-align: right;
  white-space: nowrap;
  line-height: 1.15vw;
  margin: 0.41vw 0 0 1.35vw;
}
.image-text_39 {
  width: 5.21vw;
  height: 6.78vw;
  margin: 2.5vw 0 0 2.39vw;
}
.block_8 {
  background-color: #ffffff;
  width: 5.21vw;
  height: 5.21vw;
}
.text-group_15 {
  width: 2.5vw;
  height: 1.15vw;
  overflow-wrap: break-word;
  color: #ffffff;
  font-size: 0.83vw;
  font-family: PingFang-SC-Regular;
  font-weight: NaN;
  text-align: right;
  white-space: nowrap;
  line-height: 1.15vw;
  margin: 0.41vw 0 0 1.35vw;
}
.image_5 {
  width: 91.72vw;
  height: 0.06vw;
  margin: 0.93vw 0 0 4.11vw;
}
.text_30 {
  width: 22.19vw;
  height: 1.15vw;
  overflow-wrap: break-word;
  color: #ffffff;
  font-size: 0.83vw;
  font-family: PingFang-SC-Regular;
  font-weight: NaN;
  text-align: right;
  white-space: nowrap;
  line-height: 1.15vw;
  margin: 1.04vw 0 1.04vw 38.85vw;
}
.box_12 {
  box-shadow: 0px 2px 10px 0px #ffffff;
  height: 6.05vw;
  width: 4.69vw;
  position: absolute;
  left: 4.17vw;
  top: -1.97vw;
}
.block_9 {
  height: 1.46vw;
  background: url(./img/SketchPng00cd75407cadcb73c07d5526fe91ab37026b55448769fa4c303c3976fa8afa88.png) -1.2vw -1.31vw no-repeat;
  background-size: 5.57vw 4.06vw;
  width: 3.18vw;
  margin: 4.53vw 0 0 0.31vw;
}
.box_13 {
  width: 1.88vw;
  height: 0.63vw;
  background: url(./img/SketchPng9ef4913d9d6240ad1b53e12a52a479f7a0da1b68c2600c2349ff3965eb212584.png) -0.47vw -0.53vw no-repeat;
  background-size: 2.81vw 1.61vw;
  margin: 0.31vw 0 0 0.67vw;
}
.image_6 {
  position: absolute;
  left: 0;
  top: 0;
  width: 4.69vw;
  height: 5.32vw;
}
