.group_8 {
  width: 100vw;
  height: 127.56vw;
  margin-bottom: 15.06vw;
}
.group_9 {
  width: 91.67vw;
  height: 121.88vw;
  margin: 2.08vw 0 0 4.16vw;
}
