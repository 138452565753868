.page {
  background-color: #ffffff;
  position: relative;
  width: 100vw;
  height: 237.82vw;
  overflow: hidden;
}
.box_1 {
  height: 4.59vw;
  background: url(./img/SketchPng1dce9298fb38b7e9cfe82a32c00329defb2206a439396e82849769b6fb3d9a85.png) 0vw 0vw no-repeat;
  background-size: 99.94vw 4.63vw;
  width: 99.95vw;
}
.box_24 {
  width: 3.75vw;
  height: 0.32vw;
}
.block_1 {
  width: 3.75vw;
  height: 0.32vw;
  background: url(./img/SketchPnge241ff7a4576b00b663c72f206afeda67344f75965e098c8f410d944d423b92c.png) 100% no-repeat;
  background-size: 100% 100%;
}
.box_25 {
  width: 91.72vw;
  height: 1.57vw;
  margin: 1.19vw 0 1.51vw 4.16vw;
}
.image_1 {
  width: 13.34vw;
  height: 1.57vw;
}
.image_2 {
  width: 8.39vw;
  height: 1.25vw;
  margin: 0.15vw 0 0 1.3vw;
}
.image_3 {
  width: 5.84vw;
  height: 1.57vw;
  margin-left: 1.25vw;
}
.text_1 {
  width: 1.88vw;
  height: 1.31vw;
  overflow-wrap: break-word;
  color: #334155;
  font-size: 0.93vw;
  font-family: PingFang-SC-Regular;
  font-weight: NaN;
  text-align: center;
  white-space: nowrap;
  line-height: 1.31vw;
  margin: 0.15vw 0 0 27.65vw;
}
.text_2 {
  width: 3.75vw;
  height: 1.31vw;
  overflow-wrap: break-word;
  color: #4d6bfe;
  font-size: 0.93vw;
  font-family: PingFang-SC-Medium;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 1.31vw;
  margin: 0.15vw 0 0 1.77vw;
}
.text_3 {
  width: 3.75vw;
  height: 1.31vw;
  overflow-wrap: break-word;
  color: #334155;
  font-size: 0.93vw;
  font-family: PingFang-SC-Regular;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 1.31vw;
  margin: 0.15vw 0 0 1.77vw;
}
.text_4 {
  width: 3.75vw;
  height: 1.31vw;
  overflow-wrap: break-word;
  color: #334155;
  font-size: 0.93vw;
  font-family: PingFang-SC-Regular;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 1.31vw;
  margin: 0.15vw 0 0 1.77vw;
}
.text_5 {
  width: 3.75vw;
  height: 1.31vw;
  overflow-wrap: break-word;
  color: #334155;
  font-size: 0.93vw;
  font-family: PingFang-SC-Regular;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 1.31vw;
  margin: 0.15vw 0 0 1.77vw;
}
.section_1 {
  width: 1.05vw;
  height: 1.05vw;
  background: url(./img/SketchPngcf70cb1cd4a6e4baec70c06d360ead579c68b1ac93462218499a786418461b34.png) 100% no-repeat;
  background-size: 100% 100%;
  margin: 0.31vw 0 0 1.77vw;
}
.text_6 {
  width: 6.78vw;
  height: 1.31vw;
  overflow-wrap: break-word;
  color: #334155;
  font-size: 0.93vw;
  font-family: PingFang-SC-Bold;
  font-weight: NaN;
  text-align: center;
  white-space: nowrap;
  line-height: 1.31vw;
  margin: 0.15vw 0 0 0.41vw;
}
.block_4 {
  width: 100vw;
  height: 218.18vw;
  margin-bottom: 15.06vw;
}
.box_26 {
  width: 91.67vw;
  height: 212.61vw;
  margin: 2.08vw 0 0 4.16vw;
}
.section_4 {
  width: 21.25vw;
  height: 31.25vw;
}
.text-wrapper_1 {
  border-radius: 40px;
  height: 4.17vw;
  border: 1px solid #93a3b8;
  width: 21.25vw;
}
.text_7 {
  width: 6.25vw;
  height: 1.57vw;
  overflow-wrap: break-word;
  color: #334155;
  font-size: 1.56vw;
  font-family: PingFang-SC-Regular;
  font-weight: NaN;
  text-align: right;
  white-space: nowrap;
  line-height: 1.57vw;
  margin: 1.3vw 0 0 7.5vw;
}
.text-wrapper_2 {
  border-radius: 40px;
  height: 4.17vw;
  border: 1px solid;
  margin-top: 1.25vw;
  width: 21.25vw;
}
.text_8 {
  width: 3.13vw;
  height: 2.19vw;
  overflow-wrap: break-word;
  color: #4d6bfe;
  font-size: 1.56vw;
  font-family: PingFang-SC-Bold;
  font-weight: NaN;
  text-align: right;
  white-space: nowrap;
  line-height: 2.19vw;
  margin: 0.98vw 0 0 9.06vw;
}
.text-wrapper_3 {
  border-radius: 40px;
  height: 4.17vw;
  border: 1px solid #93a3b8;
  margin-top: 1.25vw;
  width: 21.25vw;
}
.text_9 {
  width: 3.13vw;
  height: 2.19vw;
  overflow-wrap: break-word;
  color: #334155;
  font-size: 1.56vw;
  font-family: PingFang-SC-Regular;
  font-weight: NaN;
  text-align: right;
  white-space: nowrap;
  line-height: 2.19vw;
  margin: 0.98vw 0 0 9.06vw;
}
.text-wrapper_4 {
  border-radius: 40px;
  height: 4.17vw;
  border: 1px solid #93a3b8;
  margin-top: 1.25vw;
  width: 21.25vw;
}
.text_10 {
  width: 6.25vw;
  height: 2.19vw;
  overflow-wrap: break-word;
  color: #334155;
  font-size: 1.56vw;
  font-family: PingFang-SC-Regular;
  font-weight: NaN;
  text-align: right;
  white-space: nowrap;
  line-height: 2.19vw;
  margin: 0.98vw 0 0 7.5vw;
}
.text-wrapper_5 {
  border-radius: 40px;
  height: 4.17vw;
  border: 1px solid #93a3b8;
  margin-top: 1.25vw;
  width: 21.25vw;
}
.text_11 {
  width: 3.13vw;
  height: 2.19vw;
  overflow-wrap: break-word;
  color: #334155;
  font-size: 1.56vw;
  font-family: PingFang-SC-Regular;
  font-weight: NaN;
  text-align: right;
  white-space: nowrap;
  line-height: 2.19vw;
  margin: 0.98vw 0 0 9.06vw;
}
.text-wrapper_6 {
  border-radius: 40px;
  height: 4.17vw;
  border: 1px solid #93a3b8;
  margin-top: 1.25vw;
  width: 21.25vw;
}
.text_12 {
  width: 3.13vw;
  height: 2.19vw;
  overflow-wrap: break-word;
  color: #334155;
  font-size: 1.56vw;
  font-family: PingFang-SC-Regular;
  font-weight: NaN;
  text-align: right;
  white-space: nowrap;
  line-height: 2.19vw;
  margin: 0.98vw 0 0 9.06vw;
}
.box_6 {
  border-radius: 40px;
  background-image: url(./img/ec70b98a874a460681b92cdf9ff04dae_mergeImage.png);
  position: relative;
  width: 68.34vw;
  height: 212.61vw;
  border: 1px solid #93a3b8;
  overflow: hidden;
}
.section_5 {
  background-image: url(./img/6d2b83bff2d345a39188c8706de0deca_mergeImage.png);
  width: 68.29vw;
  height: 22.71vw;
  border: 1px solid #979797;
}
.text_13 {
  width: 12.09vw;
  height: 3.55vw;
  overflow-wrap: break-word;
  color: #334155;
  font-size: 3.02vw;
  font-family: PingFang-SC-Bold;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 3.55vw;
  margin: 1.92vw 0 0 2.29vw;
}
.text-group_11 {
  width: 63.75vw;
  height: 12.82vw;
  margin: 2.08vw 0 0 2.29vw;
}
.paragraph_1 {
  width: 63.75vw;
  height: 7.19vw;
  overflow-wrap: break-word;
  color: #65748b;
  font-size: 1.04vw;
  font-family: PingFang-SC-Regular;
  font-weight: NaN;
  text-align: justify;
  line-height: 2.4vw;
}
.text_14 {
  width: 21.52vw;
  height: 3.55vw;
  overflow-wrap: break-word;
  color: #334155;
  font-size: 2.5vw;
  font-family: PingFang-SC-Bold;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 3.55vw;
  margin-top: 2.09vw;
}
.section_6 {
  width: 61.88vw;
  height: 17.5vw;
  margin: 2.08vw 0 0 2.29vw;
}
.text-wrapper_7 {
  border-radius: 40px;
  background-image: url(./img/8f03420c11a0453db7a312cd5a3f789a_mergeImage.png);
  width: 19.8vw;
  height: 17.5vw;
  border: 1px solid #93a3b8;
  justify-content: flex-center;
}
.text_15 {
  width: 9.38vw;
  height: 1.98vw;
  overflow-wrap: break-word;
  color: #334155;
  font-size: 1.56vw;
  font-family: PingFang-SC-Bold;
  font-weight: NaN;
  text-align: right;
  white-space: nowrap;
  line-height: 1.98vw;
  margin: 4.06vw 0 0 5.2vw;
}
.paragraph_2 {
  width: 7.77vw;
  height: 4.17vw;
  overflow-wrap: break-word;
  color: #65748b;
  font-size: 1.25vw;
  font-family: PingFang-SC-Regular;
  font-weight: NaN;
  text-align: right;
  line-height: 2.09vw;
  margin: 3.22vw 0 4.06vw 10.78vw;
}
.text-wrapper_8 {
  border-radius: 40px;
  background-image: url(./img/6c977df74283457dada34ac28172fff2_mergeImage.png);
  width: 19.8vw;
  height: 17.5vw;
  border: 1px solid #93a3b8;
  margin-left: 1.25vw;
}
.paragraph_3 {
  width: 9.38vw;
  height: 3.96vw;
  overflow-wrap: break-word;
  color: #334155;
  font-size: 1.56vw;
  font-family: PingFang-SC-Bold;
  font-weight: NaN;
  text-align: right;
  line-height: 1.98vw;
  margin: 2.08vw 0 0 5.2vw;
}
.paragraph_4 {
  width: 12.5vw;
  height: 4.17vw;
  overflow-wrap: break-word;
  color: #65748b;
  font-size: 1.25vw;
  font-family: PingFang-SC-Regular;
  font-weight: NaN;
  text-align: right;
  line-height: 2.09vw;
  margin: 3.22vw 0 4.06vw 6.04vw;
}
.text-wrapper_9 {
  border-radius: 40px;
  background-image: url(./img/bde28efc00d246ee8f631178d3a07e9e_mergeImage.png);
  width: 19.8vw;
  height: 17.5vw;
  border: 1px solid #93a3b8;
  margin-left: 1.25vw;
  justify-content: flex-center;
}
.text_16 {
  width: 12.5vw;
  height: 1.98vw;
  overflow-wrap: break-word;
  color: #334155;
  font-size: 1.56vw;
  font-family: PingFang-SC-Bold;
  font-weight: NaN;
  text-align: right;
  white-space: nowrap;
  line-height: 1.98vw;
  margin: 4.06vw 0 0 3.64vw;
}
.paragraph_5 {
  width: 7.5vw;
  height: 4.17vw;
  overflow-wrap: break-word;
  color: #65748b;
  font-size: 1.25vw;
  font-family: PingFang-SC-Regular;
  font-weight: NaN;
  text-align: right;
  line-height: 2.09vw;
  margin: 3.22vw 0 4.06vw 11.04vw;
}
.text_17 {
  width: 24.02vw;
  height: 3.55vw;
  overflow-wrap: break-word;
  color: #334155;
  font-size: 2.5vw;
  font-family: PingFang-SC-Bold;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 3.55vw;
  margin: 5.52vw 0 0 2.29vw;
}
.text_18 {
  width: 30.21vw;
  height: 2.4vw;
  overflow-wrap: break-word;
  color: #65748b;
  font-size: 1.04vw;
  font-family: PingFang-SC-Regular;
  font-weight: NaN;
  text-align: justify;
  line-height: 2.4vw;
  margin: 2.08vw 0 0 2.29vw;
}
.text_19 {
  width: 9.38vw;
  height: 1.98vw;
  overflow-wrap: break-word;
  color: #334155;
  font-size: 1.56vw;
  font-family: PingFang-SC-Bold;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 1.98vw;
  margin: 1.56vw 0 0 2.29vw;
}
.section_7 {
  width: 63.75vw;
  height: 15.27vw;
  margin: 1.56vw 0 0 2.29vw;
}
.text-wrapper_10 {
  box-shadow: 0px 0px 20px 0px rgba(51, 65, 85, 0.1);
  border-radius: 40px;
  background-image: url(./img/f4fa692eb86f4f6090d75ae4f4b7d383_mergeImage.png);
  height: 15.27vw;
  border: 1px solid #93a3b8;
  width: 19.8vw;
}
.text_20 {
  width: 5vw;
  height: 1.57vw;
  overflow-wrap: break-word;
  color: #334155;
  font-size: 1.25vw;
  font-family: PingFang-SC-Regular;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 1.57vw;
  margin: 11.61vw 0 0 1.56vw;
}
.text-wrapper_11 {
  box-shadow: 0px 0px 20px 0px rgba(51, 65, 85, 0.1);
  border-radius: 40px;
  background-image: url(./img/3493d882073a4106b758c7426a8dfc33_mergeImage.png);
  height: 15.27vw;
  border: 1px solid #93a3b8;
  margin-left: 2.19vw;
  width: 19.8vw;
}
.text_21 {
  width: 8.75vw;
  height: 1.57vw;
  overflow-wrap: break-word;
  color: #334155;
  font-size: 1.25vw;
  font-family: PingFang-SC-Regular;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 1.57vw;
  margin: 11.61vw 0 0 1.56vw;
}
.text-wrapper_12 {
  box-shadow: 0px 0px 20px 0px rgba(51, 65, 85, 0.1);
  border-radius: 40px;
  background-image: url(./img/cb3312656c54498b9409f7f1d7bff0c8_mergeImage.png);
  height: 15.27vw;
  border: 1px solid #93a3b8;
  margin-left: 2.19vw;
  width: 19.8vw;
}
.text_22 {
  width: 8.75vw;
  height: 1.57vw;
  overflow-wrap: break-word;
  color: #334155;
  font-size: 1.25vw;
  font-family: PingFang-SC-Regular;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 1.57vw;
  margin: 11.61vw 0 0 1.56vw;
}
.list_3 {
  width: 63.75vw;
  height: 15.27vw;
  justify-content: space-between;
  margin: 2.08vw 0 0 2.29vw;
}
.text-wrapper_13-0 {
  box-shadow: 0px 0px 20px 0px rgba(51, 65, 85, 0.1);
  border-radius: 40px;
  background-image: url(./img/a14740e88272457f88bd2e21b9e9d41b_mergeImage.png);
  height: 15.27vw;
  border: 1px solid #93a3b8;
  margin-right: 2.19vw;
  width: 19.8vw;
  background: url(./img/a14740e88272457f88bd2e21b9e9d41b_mergeImage.png);
}
.text_23-0 {
  width: 13.75vw;
  height: 1.57vw;
  overflow-wrap: break-word;
  color: #334155;
  font-size: 1.25vw;
  font-family: PingFang-SC-Regular;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 1.57vw;
  margin: 11.61vw 0 0 1.56vw;
}
.text-wrapper_13-1 {
  box-shadow: 0px 0px 20px 0px rgba(51, 65, 85, 0.1);
  border-radius: 40px;
  background-image: url(./img/a14740e88272457f88bd2e21b9e9d41b_mergeImage.png);
  height: 15.27vw;
  border: 1px solid #93a3b8;
  margin-right: 2.19vw;
  width: 19.8vw;
  background: url(./img/28d90cb1096943c096e5729df43a2850_mergeImage.png);
}
.text_23-1 {
  width: 13.75vw;
  height: 1.57vw;
  overflow-wrap: break-word;
  color: #334155;
  font-size: 1.25vw;
  font-family: PingFang-SC-Regular;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 1.57vw;
  margin: 11.61vw 0 0 1.56vw;
}
.text-wrapper_13-2 {
  box-shadow: 0px 0px 20px 0px rgba(51, 65, 85, 0.1);
  border-radius: 40px;
  background-image: url(./img/a14740e88272457f88bd2e21b9e9d41b_mergeImage.png);
  height: 15.27vw;
  border: 1px solid #93a3b8;
  margin-right: 2.19vw;
  width: 19.8vw;
  background: url(./img/c76d5775d9eb4edb8a3500393b899044_mergeImage.png);
}
.text_23-2 {
  width: 13.75vw;
  height: 1.57vw;
  overflow-wrap: break-word;
  color: #334155;
  font-size: 1.25vw;
  font-family: PingFang-SC-Regular;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 1.57vw;
  margin: 11.61vw 0 0 1.56vw;
}
.text_24 {
  width: 6.25vw;
  height: 1.98vw;
  overflow-wrap: break-word;
  color: #334155;
  font-size: 1.56vw;
  font-family: PingFang-SC-Bold;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 1.98vw;
  margin: 2.08vw 0 0 2.29vw;
}
.section_8 {
  width: 63.75vw;
  height: 15.27vw;
  margin: 1.56vw 0 0 2.29vw;
}
.text-wrapper_14 {
  box-shadow: 0px 0px 20px 0px rgba(51, 65, 85, 0.1);
  border-radius: 40px;
  background-image: url(./img/c142fb152e424fcba33e4345ff19f4e0_mergeImage.png);
  height: 15.27vw;
  border: 1px solid #93a3b8;
  width: 19.8vw;
}
.text_25 {
  width: 5vw;
  height: 1.57vw;
  overflow-wrap: break-word;
  color: #334155;
  font-size: 1.25vw;
  font-family: PingFang-SC-Regular;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 1.57vw;
  margin: 11.61vw 0 0 1.56vw;
}
.text-wrapper_15 {
  box-shadow: 0px 0px 20px 0px rgba(51, 65, 85, 0.1);
  border-radius: 40px;
  background-image: url(./img/ea65a4c9e4584e27bf5ed6d2acfa22aa_mergeImage.png);
  height: 15.27vw;
  border: 1px solid #93a3b8;
  margin-left: 2.19vw;
  width: 19.8vw;
}
.text_26 {
  width: 5vw;
  height: 1.57vw;
  overflow-wrap: break-word;
  color: #334155;
  font-size: 1.25vw;
  font-family: PingFang-SC-Regular;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 1.57vw;
  margin: 11.61vw 0 0 1.56vw;
}
.text-wrapper_16 {
  box-shadow: 0px 0px 20px 0px rgba(51, 65, 85, 0.1);
  border-radius: 40px;
  background-image: url(./img/60803296fe554f4ca0248b065a815a80_mergeImage.png);
  height: 15.27vw;
  border: 1px solid #93a3b8;
  margin-left: 2.19vw;
  width: 19.8vw;
}
.text_27 {
  width: 8.75vw;
  height: 1.57vw;
  overflow-wrap: break-word;
  color: #334155;
  font-size: 1.25vw;
  font-family: PingFang-SC-Regular;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 1.57vw;
  margin: 11.61vw 0 0 1.56vw;
}
.text-group_12 {
  width: 47.92vw;
  height: 7.5vw;
  margin: 2.29vw 0 0 2.29vw;
}
.text_28 {
  width: 11.52vw;
  height: 3.55vw;
  overflow-wrap: break-word;
  color: #334155;
  font-size: 2.5vw;
  font-family: PingFang-SC-Bold;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 3.55vw;
}
.text_29 {
  width: 47.92vw;
  height: 2.4vw;
  overflow-wrap: break-word;
  color: #65748b;
  font-size: 1.04vw;
  font-family: PingFang-SC-Regular;
  font-weight: NaN;
  text-align: justify;
  line-height: 2.4vw;
  margin-top: 1.57vw;
}
.box_10 {
  border-radius: 40px;
  background-image: url(./img/3669ae2dc8d24902bf2425e70ad3b4bc_mergeImage.png);
  height: 35.84vw;
  border: 1px solid #93a3b8;
  width: 63.75vw;
  margin: 1.56vw 0 0 2.29vw;
}
.text-wrapper_17 {
  width: 63.75vw;
  height: 7.14vw;
  background: url(./img/SketchPng2d5e544b8f85d439574f548664a407fdd7e4c9a9115be809e42b70f838b63793.png) 100% no-repeat;
  background-size: 100% 100%;
  margin-top: 28.7vw;
}
.text_30 {
  width: 7.82vw;
  height: 1.57vw;
  overflow-wrap: break-word;
  color: #ffffff;
  font-size: 1.56vw;
  font-family: PingFang-SC-Bold;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 1.57vw;
  margin: 1.56vw 0 0 1.56vw;
}
.text_31 {
  width: 11.46vw;
  height: 1.05vw;
  overflow-wrap: break-word;
  color: #ffffff;
  font-size: 1.04vw;
  font-family: PingFang-SC-Regular;
  font-weight: NaN;
  text-align: justify;
  white-space: nowrap;
  line-height: 1.05vw;
  margin: 1.14vw 0 1.82vw 1.56vw;
}
.section_9 {
  width: 63.75vw;
  height: 6.78vw;
  margin: 1.25vw 0 0 2.29vw;
}
.group_2 {
  border-radius: 40px;
  background-image: url(./img/916c5277bc1d4e86a38c1ddfc92a1068_mergeImage.png);
  width: 11.46vw;
  height: 6.46vw;
  border: 1px solid #93a3b8;
  margin-top: 0.32vw;
}
.group_3 {
  border-radius: 40px;
  background-image: url(./img/4b149bbb75f8459284f5abec5451fbba_mergeImage.png);
  width: 11.46vw;
  height: 6.46vw;
  border: 1px solid #93a3b8;
  margin: 0.31vw 0 0 1.61vw;
}
.group_4 {
  box-shadow: 0px 6px 20px 0px rgba(51, 65, 85, 0.5);
  height: 6.46vw;
  margin-left: 1.62vw;
  width: 11.46vw;
}
.box_12 {
  border-radius: 40px;
  background-image: url(./img/71dc66be196543a799910e0aa40c4d56_mergeImage.png);
  width: 11.46vw;
  height: 6.46vw;
  border: 4px solid;
}
.group_5 {
  border-radius: 40px;
  background-image: url(./img/5889df1aeb5441c2a7b8838a13d36ee1_mergeImage.png);
  width: 11.46vw;
  height: 6.46vw;
  border: 1px solid #93a3b8;
  margin: 0.31vw 0 0 1.61vw;
}
.group_6 {
  border-radius: 40px;
  background-image: url(./img/a1fb2e8b893c420ab4432a5884e509bb_mergeImage.png);
  width: 11.46vw;
  height: 6.46vw;
  border: 1px solid #93a3b8;
  margin: 0.31vw 0 0 1.61vw;
}
.box_13 {
  background-color: #ffffff;
  border-radius: 40px;
  width: 63.75vw;
  height: 13.96vw;
  border: 1px solid #93a3b8;
  justify-content: flex-center;
  margin: 6.35vw 0 2.29vw 2.29vw;
}
.text_32 {
  width: 40vw;
  height: 2.5vw;
  overflow-wrap: break-word;
  color: #334155;
  font-size: 2.5vw;
  font-family: PingFang-SC-Bold;
  font-weight: NaN;
  text-align: right;
  white-space: nowrap;
  line-height: 2.5vw;
  margin: 3.02vw 0 0 11.87vw;
}
.group_7 {
  width: 11.88vw;
  height: 3.34vw;
  background: url(./img/SketchPng711a9d031e93635d8f626f305e03f7d02acc80f914541785dc810a61fc18c466.png) 100% no-repeat;
  background-size: 100% 100%;
  margin: 2.08vw 0 3.02vw 25.93vw;
}
.image-text_9 {
  width: 6.88vw;
  height: 1.57vw;
  margin: 0.88vw 0 0 2.5vw;
}
.text-group_3 {
  width: 5vw;
  height: 1.25vw;
  overflow-wrap: break-word;
  color: #ffffff;
  font-size: 1.25vw;
  font-family: PingFang-SC-Medium;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 1.25vw;
  margin-top: 0.16vw;
}
.label_1 {
  width: 1.57vw;
  height: 1.57vw;
}
.box_14 {
  border-radius: 40px;
  background-image: url(./img/ae824e75e80845b0bea146b079b611cc_mergeImage.png);
  height: 17.5vw;
  border: 1px solid #93a3b8;
  width: 19.8vw;
  position: absolute;
  left: 65.42vw;
  top: 45.16vw;
}
.text-wrapper_22 {
  width: 9.38vw;
  height: 3.96vw;
  margin: 2.08vw 0 0 5.2vw;
}
.paragraph_6 {
  width: 9.38vw;
  height: 3.96vw;
  overflow-wrap: break-word;
  color: #334155;
  font-size: 1.56vw;
  font-family: PingFang-SC-Bold;
  font-weight: NaN;
  text-align: right;
  line-height: 1.98vw;
}
.text-wrapper_23 {
  width: 1.67vw;
  height: 4.17vw;
  margin: 3.22vw 0 4.06vw 16.87vw;
}
.paragraph_7 {
  width: 1.67vw;
  height: 4.17vw;
  overflow-wrap: break-word;
  color: #65748b;
  font-size: 1.25vw;
  font-family: PingFang-SC-Regular;
  font-weight: NaN;
  text-align: right;
  line-height: 2.09vw;
}
.box_15 {
  background-color: #334155;
  position: absolute;
  left: 0;
  top: 222.71vw;
  width: 99.95vw;
  height: 15.11vw;
}
.block_5 {
  width: 73.29vw;
  height: 9.28vw;
  margin: 1.61vw 0 0 4.16vw;
}
.image_4 {
  width: 3.34vw;
  height: 3.34vw;
  margin-top: 3.7vw;
}
.block_6 {
  width: 28.44vw;
  height: 7.82vw;
  margin-left: 7.24vw;
}
.text-group_13 {
  width: 28.44vw;
  height: 3.65vw;
}
.text_33 {
  width: 5vw;
  height: 1.57vw;
  overflow-wrap: break-word;
  color: #ffffff;
  font-size: 1.25vw;
  font-family: PingFang-SC-Bold;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 1.57vw;
}
.text_34 {
  width: 28.39vw;
  height: 1.15vw;
  overflow-wrap: break-word;
  color: #ffffff;
  font-size: 0.83vw;
  font-family: PingFang-SC-Regular;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 1.15vw;
  margin: 0.93vw 0 0 0.05vw;
}
.text_35 {
  width: 26.57vw;
  height: 1.15vw;
  overflow-wrap: break-word;
  color: #ffffff;
  font-size: 0.83vw;
  font-family: PingFang-SC-Regular;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 1.15vw;
  margin: 0.93vw 0 0 0.05vw;
}
.text_36 {
  width: 13.18vw;
  height: 1.15vw;
  overflow-wrap: break-word;
  color: #ffffff;
  font-size: 0.83vw;
  font-family: PingFang-SC-Regular;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 1.15vw;
  margin: 0.93vw 0 0 0.05vw;
}
.block_7 {
  width: 5.21vw;
  height: 9.28vw;
  margin-left: 13.86vw;
}
.text_37 {
  width: 5vw;
  height: 1.57vw;
  overflow-wrap: break-word;
  color: #ffffff;
  font-size: 1.25vw;
  font-family: PingFang-SC-Bold;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 1.57vw;
}
.image-text_10 {
  width: 5.21vw;
  height: 6.78vw;
  margin-top: 0.94vw;
}
.box_17 {
  background-color: #ffffff;
  width: 5.21vw;
  height: 5.21vw;
}
.text-group_5 {
  width: 2.5vw;
  height: 1.15vw;
  overflow-wrap: break-word;
  color: #ffffff;
  font-size: 0.83vw;
  font-family: PingFang-SC-Regular;
  font-weight: NaN;
  text-align: right;
  white-space: nowrap;
  line-height: 1.15vw;
  margin: 0.41vw 0 0 1.35vw;
}
.image-text_11 {
  width: 5.21vw;
  height: 6.78vw;
  margin: 2.5vw 0 0 2.39vw;
}
.box_18 {
  background-color: #ffffff;
  width: 5.21vw;
  height: 5.21vw;
}
.text-group_6 {
  width: 2.5vw;
  height: 1.15vw;
  overflow-wrap: break-word;
  color: #ffffff;
  font-size: 0.83vw;
  font-family: PingFang-SC-Regular;
  font-weight: NaN;
  text-align: right;
  white-space: nowrap;
  line-height: 1.15vw;
  margin: 0.41vw 0 0 1.35vw;
}
.image-text_12 {
  width: 5.21vw;
  height: 6.78vw;
  margin: 2.5vw 0 0 2.39vw;
}
.block_2 {
  background-color: #ffffff;
  width: 5.21vw;
  height: 5.21vw;
}
.text-group_7 {
  width: 2.5vw;
  height: 1.15vw;
  overflow-wrap: break-word;
  color: #ffffff;
  font-size: 0.83vw;
  font-family: PingFang-SC-Regular;
  font-weight: NaN;
  text-align: right;
  white-space: nowrap;
  line-height: 1.15vw;
  margin: 0.41vw 0 0 1.35vw;
}
.image_5 {
  width: 91.72vw;
  height: 0.06vw;
  margin: 0.93vw 0 0 4.11vw;
}
.text_38 {
  width: 22.19vw;
  height: 1.15vw;
  overflow-wrap: break-word;
  color: #ffffff;
  font-size: 0.83vw;
  font-family: PingFang-SC-Regular;
  font-weight: NaN;
  text-align: right;
  white-space: nowrap;
  line-height: 1.15vw;
  margin: 1.04vw 0 1.04vw 38.85vw;
}
.box_19 {
  box-shadow: 0px 2px 10px 0px #ffffff;
  height: 6.05vw;
  width: 4.69vw;
  position: absolute;
  left: 4.17vw;
  top: -1.97vw;
}
.box_20 {
  height: 1.46vw;
  background: url(./img/SketchPng00cd75407cadcb73c07d5526fe91ab37026b55448769fa4c303c3976fa8afa88.png) -1.2vw -1.31vw no-repeat;
  background-size: 5.57vw 4.06vw;
  width: 3.18vw;
  margin: 4.53vw 0 0 0.31vw;
}
.box_21 {
  width: 1.88vw;
  height: 0.63vw;
  background: url(./img/SketchPng9ef4913d9d6240ad1b53e12a52a479f7a0da1b68c2600c2349ff3965eb212584.png) -0.47vw -0.53vw no-repeat;
  background-size: 2.81vw 1.61vw;
  margin: 0.31vw 0 0 0.67vw;
}
.image_6 {
  position: absolute;
  left: 0;
  top: 0;
  width: 4.69vw;
  height: 5.32vw;
}
