iframe {
  display: none !important;
}
body * {
  box-sizing: border-box;
  flex-shrink: 0;
  font-family: PingFangSC-Regular, Roboto, Helvetica Neue, Helvetica, Tahoma, Arial, PingFang SC-Light, Microsoft YaHei;
  overflow-x: hidden;
  overflow-y: hidden;
}
button {
  margin: 0;
  padding: 0;
  border: 1px solid transparent;
  outline: none;
  background-color: transparent;
}
button:active {
  opacity: 0.6;
}
.flex-col {
  display: flex;
  flex-direction: column;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.justify-start {
  display: flex;
  justify-content: flex-start;
}
.justify-center {
  display: flex;
  justify-content: center;
}
.justify-end {
  display: flex;
  justify-content: flex-end;
}
.justify-evenly {
  display: flex;
  justify-content: space-evenly;
}
.justify-around {
  display: flex;
  justify-content: space-around;
}
.justify-between {
  display: flex;
  justify-content: space-between;
}
.align-start {
  display: flex;
  align-items: flex-start;
}
.align-center {
  display: flex;
  align-items: center;
}
.align-end {
  display: flex;
  align-items: flex-end;
}
/* 自定义滚动条样式 */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;
}
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
::-webkit-scrollbar-corner {
  background: #f1f1f1;
}
.clickable {
  transition: transform 0.2s ease, filter 0.2s ease;
  cursor: pointer;
}
.clickable:hover {
  transform: scale(0.95);
  filter: brightness(0.9);
}
.clickable:active {
  transform: scale(0.95);
}
@keyframes animateSlide {
  0% {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    transform: translateZ(0);
  }
}
@keyframes fadeOut {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* 应用淡出动画 */
.fade-out {
  animation: fadeOut 2s;
  /* 动画持续时间为2秒 */
}
@keyframes animateLeft {
  0% {
    opacity: 0;
    transform: translateX(50px);
    transition: all 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
    transition: all 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }
}
.slide-left {
  animation: animateLeft 2s;
}
@keyframes animateRight {
  0% {
    opacity: 0;
    transform: translateX(-50px);
    transition: all 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
    transition: all 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }
}
.slide-right {
  animation: animateRight 2s;
}
.animate_slide {
  animation: animateSlide 2s;
}
.duration-500 {
  transition-duration: 0.5s;
}
.duration-200 {
  transition-duration: 0.2s;
}
.delay-500 {
  transition-delay: 0.5s;
}
.delay-1000 {
  transition-delay: 1s;
}
.delay-300 {
  transition-delay: 0.3s;
}
.delay-600 {
  transition-delay: 0.6s;
}
.page-section.active {
  opacity: 1;
  transform: translate(0, 0);
}
