@font-face {
  font-family: 'PingFang-SC-Regular';
  src: url('/assets/fonts/pingfangscregular_downcc/PingFang Regular.otf') format('otf'), /* 支持现代浏览器 */;
}
@font-face {
  font-family: 'PingFang-SC-Bold';
  src: url('/assets/fonts/pingfangscbold_downcc/pingfangscbold_downcc.ttf') format('ttf'), /* 支持现代浏览器 */;
}
@font-face {
  font-family: 'Helvetica-Light';
  src: url('/assets/fonts/helvetica_downcc/helvetica-light.otf') format('ttf'), /* 支持现代浏览器 */;
}
@font-face {
  font-family: 'PingFang SC-Light';
  src: url('/assets/fonts/pingfangscmedium_downcc/PingFang Medium_downcc.otf') format('ttf'), /* 支持现代浏览器 */;
}
body {
  font-family: 'PingFang-SC-Regular', "Arial";
}
.indexResponse_section_1__g6BUm {
  position: fixed;
  z-index: 1;
}
.indexResponse_section_9__3IxsO {
  margin-left: 60px;
}
a:active {
  color: #333;
}
a:-webkit-any-link {
  text-decoration: none !important;
}
.indexResponse_box_3__T9fAr,
.indexResponse_group_4__CDFXq {
  display: none !important;
}
.indexResponse_box_19__QnBIi {
  justify-content: space-between;
  overflow: visible;
}
.indexResponse_image-wrapper_4__vVklQ {
  overflow: visible;
}
.indexResponse_image-wrapper_4__vVklQ img {
  width: 100% !important;
}
.indexResponse_image_6__1q8nq,
.indexResponse_image_4__2yw9A,
.indexResponse_image_4__Ncc3I,
.indexResponse_image_4__3MqGs {
  width: 0.6vw !important;
}
.indexResponse_box_21__22SLV {
  margin: 0 !important;
  padding: 20px;
  box-sizing: content-box;
}
.justify-between {
  align-items: center !important;
}
.indexResponse_text_12__2SDqp {
  margin-top: 0 !important;
}
.indexResponse_group_3__1w3uk,
.indexResponse_group_2__2ni8Y,
.indexResponse_group_2__1e-Kt,
.indexResponse_group_3__30tu0 {
  display: none !important;
}
.indexResponse_block_10__39Zsz,
.indexResponse_section_1__2gaww,
.indexResponse_group_24__1W766,
.indexResponse_box_20__1VkIY {
  border: 1px solid #356fff !important;
}
.indexResponse_block_2__1yJgF,
.indexResponse_group_12__JsAyW,
.indexResponse_section_3__2Gvv1,
.indexResponse_box_2__8d_Sh,
.indexResponse_section_5__1POZ-,
.indexResponse_block_10__39Zsz,
.indexResponse_section_16__3H9PG,
.indexResponse_section_5__3ffSf,
.indexResponse_section_13__b6Ts0,
.indexResponse_section_9__3IxsO,
.indexResponse_text_1__3KntK,
.indexResponse_group_12__J {
  overflow: visible;
}
.indexResponse_group_12__JsAyW,
.indexResponse_text_13__1cRiF {
  margin-top: 0 !important;
}
.indexResponse_paragraph_2__2B6dN {
  margin: 6vw 0 0 10.67vw !important;
}
.indexResponse_text_11__1QV02 {
  margin: 18.68vw 0 0 -23.75vw !important;
}
.indexResponse_group_22__25cCy {
  margin: 8.91vw 0 0 5.93vw !important;
}
.indexResponse_group_24__1W766 {
  top: 13.9vw !important;
}
.indexResponse_text_25__9kteb {
  margin: 5.14vw 0 0 10.67vw !important;
}
.indexResponse_text_11__1Qle_ {
  margin-top: 2.11vw !important;
}
.indexResponse_box_20__1VkIY {
  top: 15.08vw !important;
  height: 20.09vw !important;
}
.indexResponse_text_26__37p-V {
  margin: 1.28vw 0 0 2.08vw !important;
  font-size: 1.56vw !important;
}
.indexResponse_section_2__233H5,
.indexResponse_box_21__anOYa,
.indexResponse_section_3__2pGJz,
.indexResponse_block_6__2MkZ8,
.indexResponse_block_7__10xz0 {
  margin-top: 0 !important;
}
.indexResponse_text-group_7__1mHXq,
.indexResponse_text-group_8__34PWt,
.indexResponse_text-group_9__2KVVk,
.indexResponse_text-group_10__1BJDK,
.indexResponse_text-group_11__1E-4s {
  font-size: 1.25vw !important;
}
.indexResponse_section_1__2gaww {
  left: -46vw !important;
  top: 18.21vw !important;
}
.indexResponse_text-wrapper_24__MDwfc {
  margin: 4.95vw 0 0 10.62vw !important;
}
.indexResponse_image_4__3MqGs,
.indexResponse_image_4__2yw9A {
  margin: 6.43vw 0 6.51vw 38.75vw !important;
}
.indexResponse_group_11__1IbSa {
  width: 89.16vw !important;
}
.indexResponse_page__22n8A {
  height: auto !important;
}
.indexResponse_section_5__1POZ- {
  position: relative !important;
  top: 0 !important;
}
.indexResponse_box_12__29QMj {
  box-shadow: none !important;
}
.indexResponse_section_9__3IxsO a.active {
  color: #4D6BFE !important;
}
.indexResponse_text-wrapper_26__2qGaa {
  margin-left: 4.16vw !important;
}
.indexResponse_section_8__CEYtV {
  width: 2.15vw !important;
  position: absolute !important;
  left: 0 !important;
  margin-top: -1.1vw;
}
.indexResponse_section_6__Rgrlh.justify-between {
  overflow: visible;
  align-items: flex-end !important;
}
.indexResponse_text_7__xYxH2,
.indexResponse_text_3__32nG-,
.indexResponse_text_4__1Uozq,
.indexResponse_text_7__2-zwz,
.indexResponse_text_4__1_Xnh {
  transform: rotate(90deg);
  width: auto !important;
  margin-top: 5vw !important;
  white-space: nowrap;
}
.indexResponse_section_7__1pniE {
  margin-top: 5.45vw !important;
  overflow: visible !important;
}
.indexResponse_image-text_7__3KsZj {
  margin-top: 3.11vw !important;
}
.indexResponse_box_19__QnBIi {
  width: 91.61vw !important;
}
.indexResponse_text_3__32nG- {
  margin-left: -1.7vw !important;
  margin-top: 1vw !important;
}
.indexResponse_group_11__1IbSa {
  overflow: visible;
}
.indexResponse_text_4__1Uozq {
  margin-top: 10vw !important;
  margin-left: 1vw !important;
}
.indexResponse_text_7__2-zwz {
  margin-left: 0 !important;
  position: absolute;
  right: 4vw !important;
  margin-top: 8vw !important;
}
.arrow_up {
  position: absolute;
  right: 4vw;
  background: url(./img/line.png) no-repeat;
  width: 0.6vw;
  height: 7.35vw;
  background-size: contain;
  bottom: -5vw;
  z-index: 1;
}
.indexResponse_page__22n8A {
  overflow: visible !important;
}
.indexResponse_section_1__2jgJX {
  width: 13.93vw !important;
  height: 5.41vw !important;
  background-position: 0 0 !important;
  background-size: 100% !important;
  justify-content: center !important;
  align-items: center !important;
}
.indexResponse_image-text_8__F_Onh {
  margin: 0 0 0 0 !important;
}
.indexResponse_box_3__1OIRf,
.indexResponse_box_4__3shGY,
.indexResponse_list-items_1-0__CozHx,
.indexResponse_list-items_1-1__2NviJ,
.indexResponse_list-items_1-2__360nA,
.indexResponse_box_6__1JAFq {
  height: 20.7vw !important;
  width: 13.63vw !important;
}
.indexResponse_text_2__1fna5,
.indexResponse_text_3__2zJ4z,
.indexResponse_text_4-0__f8eG-,
.indexResponse_text_4-1__7l-uA,
.indexResponse_text_4-2__26MAT {
  margin: 9.78vw 0 0 2.12vw !important;
}
.indexResponse_box_22__1j5c4 {
  height: 38.25vw !important;
}
.indexResponse_text_1__3KntK {
  position: relative;
}
.indexResponse_paragraph_1__26vm9,
.indexResponse_text_25__9kteb,
.indexResponse_paragraph_2__2B6dN,
.indexResponse_text_1__2zmYt {
  font-weight: 600;
  overflow: visible;
}
.indexResponse_box_3__1tU-N,
.indexResponse_group_26__1mw-b {
  margin-left: 3.8vw !important;
  margin-top: -5vw !important;
}
.indexResponse_box_12__29QMj {
  left: 0 !important;
  margin-left: 3.6vw !important;
}
.indexResponse_block_9__21K3g {
  opacity: 0 !important;
}
.indexResponse_box_5__1yOvY,
.indexResponse_section_1__2vqlT {
  background-color: transparent !important;
}
.indexResponse_box_6__1JAFq {
  background-image: none !important;
}
.imgscroll_wrap {
  overflow-x: auto;
}
.indexResponse_list_3__2AOqN {
  position: absolute;
  overflow-x: auto;
  height: 21.7vw !important;
  width: 82.22vw !important;
}
.indexResponse_list_3__2AOqN::-webkit-scrollbar,
.indexResponse_section_6__3tUq7::-webkit-scrollbar {
  /* 竖直滚动条宽度 */
  height: 4px;
}
.indexResponse_text-wrapper_3-0__ai8yf {
  height: 20.7vw !important;
}
.indexResponse_list_3__2AOqN::-webkit-scrollbar-track,
.indexResponse_section_6__3tUq7::-webkit-scrollbar-track {
  background-color: transparent;
}
.indexResponse_box_5__1yOvY {
  position: absolute;
  right: 4vw;
  margin-top: 11.4vw !important;
}
.indexResponse_image_4__U-5XX {
  cursor: pointer;
}
.indexResponse_section_9__3IxsO a:hover {
  color: #356FFF;
}
/* 国家 */
.countryResponse_group_9__3jOrw.justify-between {
  align-items: flex-start ! important;
  overflow: visible !important;
  height: auto !important;
}
.countryResponse_group_9__3jOrw {
  margin-top: 7.08vw !important;
}
a:-webkit-any-link {
  color: #333;
}
.indexResponse_text_2__2IG7N {
  overflow: visible;
  position: relative;
}
.indexResponse_text_2__2IG7N .indexResponse_section_8__CEYtV {
  width: 3.65vw !important;
}
.indexResponse_text_2__2IG7N .indexResponse_box_1__1FnRE {
  width: 5.75vw;
}
.indexResponse_group_8__33Fga {
  margin-top: 2.6rem;
}
.indexResponse_group_2-0__IuCuN,
.indexResponse_group_2-1__1ChJu,
.indexResponse_group_2-2__3jQPE {
  background-size: contain !important;
}
.indexResponse_list-items_1-0__2LkCc,
.indexResponse_list-items_1-1__2CuqK,
.indexResponse_list-items_1-2__2e5eM {
  background-repeat: no-repeat !important;
  background-size: contain !important;
}
.indexResponse_box_6__3mdl5,
.indexResponse_block_4__3XObX,
.indexResponse_group_3__29kJI,
.indexResponse_block_5__1aIg8 {
  margin-top: 0 !important;
}
.indexResponse_section_12__2lWgb {
  align-items: flex-start !important;
}
.indexResponse_section_4__8MOlk {
  background-repeat: no-repeat;
  background-size: contain;
}
.indexResponse_image-text_27-2__2jc_t {
  margin: 0.88vw 0 0 1.3vw !important;
}
.indexResponse_group_8__33Fga {
  margin-bottom: 0 !important;
}
.countryResponse_group_8__1T4YJ {
  overflow: visible;
}
.countryMenuResponse_text_7__1P9jR {
  width: 6.25vw;
  height: 1.57vw;
  overflow-wrap: break-word;
  color: #334155 !important;
  font-size: 1.56vw;
  font-family: PingFang-SC-Bold;
  font-weight: NaN;
  text-align: right;
  white-space: nowrap;
  line-height: 1.57vw;
  margin: 1.3vw 0 0 7.5vw;
  text-align: center !important;
}
.countryMenuResponse_text_7__1P9jR.active {
  color: #4D6BFE !important;
}
.countryMenuResponse_text-wrapper_1__1fOTp {
  cursor: pointer;
}
.countryMenuResponse_section_10__RaVKI > div {
  cursor: pointer;
}
.countryMenuResponse_section_10__RaVKI > div:hover span.countryMenuResponse_text_7__1P9jR {
  color: #4D6BFE !important;
}
.align-items-center {
  align-items: center !important;
}
.marginLeft-0 {
  margin-left: 0 !important;
}
.alignCenter {
  text-align: center !important;
}
.indexResponse_text-wrapper_7__14hmd {
  background: none !important;
}
.indexResponse_text-wrapper_8__pfYlf {
  background: none !important;
}
.indexResponse_text-wrapper_9__1-JLG {
  background: none !important;
}
.indexResponse_text_15__1ciwB,
.indexResponse_paragraph_3__1bb8t,
.indexResponse_text_16__2EEhn {
  position: relative;
  overflow: visible;
}
.indexResponse_text_15__1ciwB::after,
.indexResponse_paragraph_3__1bb8t::after,
.indexResponse_text_16__2EEhn::after {
  content: "";
  width: 60px;
  height: 8px;
  background: linear-gradient(90deg, #45A6FF 0%, #356FFF 100%);
  border-radius: 4px;
  position: absolute;
  bottom: -20px;
  left: 50%;
  margin-left: -30px;
}
.countryResponse_group_8__1T4YJ {
  height: auto !important;
}
.indexResponse_text-wrapper_10__DwpF9,
.indexResponse_text-wrapper_13-0__1Gbte,
.indexResponse_text-wrapper_11__mkUsQ,
.indexResponse_text-wrapper_12__1Hdmh,
.indexResponse_text-wrapper_13-1__2hqJC,
.indexResponse_text-wrapper_13-2__tq2kD,
.indexResponse_text-wrapper_14__3Pist,
.indexResponse_text-wrapper_15__AJxgc,
.indexResponse_text-wrapper_16__kvr_y {
  background-repeat: no-repeat !important;
  background-position: center 30% !important;
  background-size: contain !important;
}
.indexResponse_box_6__2xis9 {
  background: none !important;
  height: auto !important;
}
.indexResponse_section_11__3Jzl8,
.indexResponse_section_5__2zolh {
  border: none !important;
}
.indexResponse_text-group_12__2lcYt.justify-between,
.indexResponse_text-group_21__Egfos.justify-between,
.indexResponse_text-group_11__1ikns.justify-between {
  align-items: flex-start !important;
}
.indexResponse_text_13__1awIP {
  overflow: visible !important;
}
.indexResponse_group_4__pMj-z {
  border-radius: 2.5vw !important;
}
.indexResponse_box_12__1amav {
  border: 0.3vw solid #356FFF !important;
}
.visible {
  overflow: visible !important;
}
.overhide {
  overflow: hidden !important;
}
.bold {
  font-weight: bold !important;
}
.page-prev {
  background: url(./img/icon_pag_1.png) no-repeat;
  width: 2vw;
  height: 2vw;
  background-size: contain;
  cursor: pointer;
}
.page-next {
  background: url(./img/icon_pag_2.png) no-repeat;
  width: 2vw;
  height: 2vw;
  background-size: contain;
  cursor: pointer;
}
.indexResponse_section_9__1Wpyf {
  height: 8.98vw !important;
  margin-left: 0 !important;
}
.pageArrowWrap {
  display: flex;
  justify-content: flex-end;
  grid-gap: 11px;
  gap: 11px;
}
.slidewrap {
  width: 63.75vw;
  margin-left: 2.29vw;
}
.transition {
  transition: all 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.indexResponse_section_6__3tUq7 {
  height: 19.5vw !important;
  overflow-x: auto !important;
}
.indexResponse_text_16__2EEhn {
  text-align: center !important;
}
